import React from 'react';
import { ATag, RowDiv } from 'styles/CommonCSS';
import Text from './Element/Text';
import CheckBox from './Input/CheckBox';

function AgreeBox({
  onChange,
  checked,
  name,
  label,
  link,
  viewText,
  ...props
}) {
  return (
    <RowDiv align="center" justify="space-between" {...props}>
      <CheckBox onChange={onChange} checked={checked} name={name}>
        <Text mSize={12}>{label}</Text>
      </CheckBox>
      <ATag
        href={link}
        width={44}
        minWidth={44}
        height={26}
        mWidth={40}
        mMinWidth={40}
        mHeight={24}
        target="_blank"
      >
        <Text
          underline
          textAlign="center"
          lineHeight="26px"
          mLineHeight="24px"
          mSize={12}
        >
          {viewText}
        </Text>
      </ATag>
    </RowDiv>
  );
}

export default AgreeBox;
