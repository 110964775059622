const paymethodsData = {
  kor: [
    { value: 'card', label: '신용카드' },
    { value: 'vbank', label: '가상계좌' },
  ],
  eng: [
    { value: 'card', label: 'Credit card' },
    { value: 'vbank', label: 'Virtual account' },
  ],
};

export default paymethodsData;
