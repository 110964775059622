// theme
const colors = {
  lightGray: '#DCDCE5',
  main: '#D9A796',
  sub: '#F2EDDC',

  colorFF: '#FFE4E5',
  colorDE: '#DEDEDE',
  colorCC: '#CCCCCC',
  color80: '#808080',

  background: '#F8F8F8',

  fontBlack: '#191919',
  fontGray: '#767676',
  warningColor: '#FF0000',
};

const element = {
  padding: '12px 20px',
  mPadding: '12px 10px',
  input: {
    height: '40px',
    mHeight: '35px',
    sHeight: '35px',
    padding: '11px 10px',
    mPadding: '10px',
    textareaHeight: '262px',
    mTextareaHeight: '143px',
    radius: '3px',
    checkboxType: 'visibility', // disabled or visibility
  },
  button: {
    width: '100%',
    mWidth: '100%',
    sWidth: '100%',
    height: '40px',
    mHeight: '35px',
    sHeight: '35px',
  },
};

const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '450px',
  mobile: '576px',
  tablet: '768px',
  minDesktop: '1200px',
  maxDesktop: '1400px',
};

const device = {
  mobileS: `only screen and (max-width: ${deviceSizes.mobileS})`,
  mobileM: `only screen and (max-width: ${deviceSizes.mobileM})`,
  mobileL: `only screen and (max-width: ${deviceSizes.mobileL})`,
  mobile: `only screen and (max-width: ${deviceSizes.mobile})`,
  tabletS: `only screen and (max-width: ${deviceSizes.tabletS})`,
  tablet: `only screen and (max-width: ${deviceSizes.tablet})`,
  minDesktop: `only screen and (max-width: ${deviceSizes.minDesktop})`,
  maxDesktop: `only screen and (max-width: ${deviceSizes.maxDesktop})`,
  ratio: `only screen and (max-width: 1280px) and (min-aspect-ratio: 16/9),
  screen and (max-width: 1900px) and (min-aspect-ratio: 2/1)`,
};

const theme = {
  deviceSizes,
  colors,
  device,
  element,
};

export default theme;
