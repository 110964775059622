import React from 'react';
import styled, { css } from 'styled-components';
import Text from 'components/Element/Text';
import { common, flexRow, space } from 'styles/CommonCSS';
import buttonSize from 'styles/ButtonCss';
import hexToRGB from 'modules/hexToRGB';
import Loading from './Loading';

function Button({
  loading,
  onClick,
  active,
  disabled,
  children,
  size,
  mSize,
  text,
  animation,
  ...props
}) {
  return (
    <ButtonContainer
      active={active}
      onClick={onClick}
      disabled={disabled}
      $loading={loading}
      animation={animation}
      {...props}
    >
      {loading && <Loading type="button" />}
      {children}
      <Text weight={700} size={size || 14} mSize={mSize || 12} lineHeight={1}>
        {text}
      </Text>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.button`
  ${flexRow('center', 'center')}
  ${common}
  ${space} 
  ${buttonSize}
  text-align: center;
  color: ${({ theme, type }) =>
    type === 'outline' ? theme.colors.colorCC : 'white'};
  border: 1px solid
    ${({ theme, type }) =>
      type === 'outline' ? theme.colors.colorCC : 'transparent'};
  border-radius: 20px;
  transition: all 150ms;
  cursor: ${({ $loading }) => ($loading ? 'default' : 'pointer')};
  padding: ${({ theme }) => theme.element.padding};
  position: relative;
  overflow: hidden;

  & p {
    white-space: nowrap;
    word-break: keep-all;
  }

  &:hover {
    opacity: 0.5;
  }

  ${({ type, animation, active, theme, bgColor }) =>
    animation === 'toRight'
      ? css`
          transition: all 0.3s;
          position: relative;
          overflow: hidden;
          background-color: ${hexToRGB(theme.colors.main, 0.3)};
          z-index: 1;

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: ${active ? '100%' : '0%'};
            height: 100%;
            background-color: ${theme.colors.main};
            transition: all 0.3s;
            z-index: -1;
          }
        `
      : css`
          background-color: ${type === 'outline'
            ? 'white'
            : bgColor
            ? theme.colors[bgColor]
              ? theme.colors[bgColor]
              : bgColor
            : hexToRGB(theme.colors.main, active ? 1 : 0.3)};
        `}

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => theme.element.mPadding};

    &:hover {
      opacity: 1;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: ${({ theme }) => theme.element.sPadding};

    &:hover {
      opacity: 1;
    }
  }
`;

export default Button;
