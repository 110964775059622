import Button from 'components/Element/Button';
import Text from 'components/Element/Text';
import hexToRGB from 'modules/hexToRGB';
import React from 'react';
import { Div } from 'styles/CommonCSS';
import moment from 'moment';

function History({ info, setPage, returnUrl, isMobile }) {
  return (
    <>
      <Div
        width="100%"
        padding={(isMobile && '20px 30px') || '20px'}
        bgColor="white"
        mb={10}
      >
        <Text size={18} mSize={14} weight={700} mb={10}>
          정보확인
        </Text>
        <Div
          width="100%"
          height="1px"
          bgColor={hexToRGB('colorCC', 0.56)}
          mb={(isMobile && 10) || 20}
        />
        <Text size={(isMobile && 12) || 14} weight={700} mb={4}>
          {info.info1Name}
        </Text>
        <Text size={(isMobile && 12) || 14} mb={20}>
          {info.info1}
        </Text>
        <Text size={(isMobile && 12) || 14} weight={700} mb={4}>
          {info.info2Name}
        </Text>
        <Text size={(isMobile && 12) || 14}>{info.info2}</Text>
      </Div>
      <Div
        width="100%"
        padding={(isMobile && '20px 30px 40px 30px') || '20px 20px 40px 20px'}
        bgColor="white"
        mb={10}
      >
        <Text size={18} mSize={14} weight={700} mb={10}>
          구매내역
        </Text>
        <Div
          width="100%"
          height="1px"
          bgColor={hexToRGB('colorCC', 0.56)}
          mb={20}
          mMb={10}
        />
        <Text size={(isMobile && 12) || 14} weight={700} mb={4}>
          구매금액
        </Text>
        <Text size={(isMobile && 12) || 14} mb={20}>
          {info?.price}
        </Text>
        <Text size={(isMobile && 12) || 14} weight={700} mb={4}>
          구매상세
        </Text>
        <Text size={(isMobile && 12) || 14} mb={20}>
          {info?.payInfo}
        </Text>
        <Text size={(isMobile && 12) || 14} weight={700} mb={4}>
          구매일시
        </Text>
        <Text size={(isMobile && 12) || 14} mb={40}>
          {moment(info.paidDt).format('MM월 DD일 HH:MM')}
        </Text>
        <Button
          text="확인"
          active
          mb={10}
          onClick={() => {
            if (isMobile) {
              window.location.replace(returnUrl);
            } else {
              window.close();
            }
          }}
        />
        <Button
          text="환불하기"
          type="outline"
          onClick={() => setPage('cancel')}
        />
      </Div>
    </>
  );
}

export default History;
