import React from 'react';
import Text from './Text';

function LineText({ lineText, ...props }) {
  return (
    <>
      {lineText?.split('\n').map((content, index) => (
        <Text key={`text${index}`} {...props}>
          {content}
        </Text>
      ))}
    </>
  );
}

export default LineText;
