import { paymentApi } from 'api';
import Loading from 'components/Element/Loading';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import OrderPresenter from './OrderPresenter';

function OrderContainer(props) {
  const [page, setPage] = useState('history');
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useSearchParams();
  const [returnUrl, setReturnUrl] = useState();
  const [checksumCode, setChecksumCode] = useState();
  const [paymentCode, setPaymentCode] = useState();

  const [info, setInfo] = useState();

  const getOrderInfo = async () => {
    // 결제 내역 정보
    const [info] = await paymentApi.order({ paymentCode, checksumCode });
    if (info) {
      if (info.result) {
        setInfo({ ...info.result });
        setLoading(false);
      } else {
        // 결제 내역 정보 조회 실패 처리 미정
        // resultRedirect(false);
      }
    }
  };

  useEffect(() => {
    if (returnUrl && checksumCode && paymentCode) {
      getOrderInfo();
    }
  }, [returnUrl, checksumCode, paymentCode]);

  useEffect(() => {
    if (
      params.get('returnUrl') &&
      params.get('checksumCode') &&
      params.get('paymentCode')
    ) {
      // 1. 결제내역 페이지 첫 진입
      // 올바른 url을 통해 들어왔는지 확인하기 위해 params check
      setReturnUrl(params.get('returnUrl'));
      setChecksumCode(params.get('checksumCode'));
      setPaymentCode(params.get('paymentCode'));

      // remove url query string
      params.delete('returnUrl');
      params.delete('checksumCode');
      params.delete('paymentCode');
      setParams(params);
    } else {
      // 잘못 들어온 url 처리 미정
    }
  }, []);

  // 환불 처리
  const cancelItem = async () => {
    setLoading(true);
    const [info] = await paymentApi.cancel({
      paymentCode,
      checksumCode,
      cancelReturnUrl: `${returnUrl}/cancelResult`,
    });
    if (info) {
      if (info.resCode === 200) {
        // 환불처리 완료
        resultRedirect(true);
      } else {
        resultRedirect(false);
      }
    }
  };

  const resultRedirect = (success) => {
    // 결과 return
    if (isMobile) {
      //  mobile
      window.location.replace(
        `${returnUrl}/cancelResult?isSuccess=${String(success)}`,
      );
    } else {
      // pc
      window.close();
      window.opener.location.replace(
        `${returnUrl}/cancelResult?isSuccess=${String(success)}`,
      );
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <OrderPresenter
      isMobile={isMobile}
      info={info}
      page={page}
      setPage={setPage}
      cancelItem={cancelItem}
      returnUrl={returnUrl}
    />
  );
}

export default OrderContainer;
