import { paymentApi } from 'api';
import Loading from 'components/Element/Loading';
import hexToRGB from 'modules/hexToRGB';
import React, { useEffect, useState } from 'react';
import { Div } from 'styles/CommonCSS';
import { isMobile } from 'react-device-detect';
import { slideUp } from 'styles/Animation';
import paymethodsData from 'data/paymethods';
import TEXT from 'data/language';
import LineText from 'components/Element/LineText';
import { useParams } from 'react-router-dom';
import MainPresenter from './MainPresenter';

function MainContainer({ themeHandler }) {
  const originAllPath =
    window.location.href.slice(-1) === '/'
      ? window.location.href.substring(0, window.location.href.length - 1)
      : window.location.href;

  const { paymentCode } = useParams();
  const [dim, setDim] = useState(false);
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(true);

  const [info, setInfo] = useState();
  const [productList, setProductList] = useState();
  const [selectProduct, setSelectProduct] = useState();
  const [payMethods, setPayMethods] = useState();

  const [values, setValues] = useState({
    productCode: '',
    payMethod: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    postalCode: '',
    amount: '',
  });

  /* --- start ticket info setting --- */
  const paymentInfoInquiry = async () => {
    // 1. get ticket info
    const [info] = await paymentApi.inquiry({ paymentCode });
    if (info) {
      if (info.result) {
        const { result } = info;
        themeHandler(result.info.keyColor);
        // 1 - 1. ticket info
        setInfo({
          ...result.info,
          returnUrl: result.returnUrl,
        });

        // 1 - 2. paymethod language setting
        const serverPaymethods = [...JSON.parse(result.payMethods)];
        let tempPaymethod = [];
        serverPaymethods.forEach((el) => {
          if (
            paymethodsData[result.info.sysLang].find(
              (payMethod) => payMethod.value === el,
            )
          ) {
            tempPaymethod = [
              ...tempPaymethod,
              paymethodsData[result.info.sysLang].find(
                (payMethod) => payMethod.value === el,
              ),
            ];
          }
        });
        setPayMethods([...tempPaymethod]);

        // 1 - 3. ticket list (productList)
        setProductList(result.productList);

        // 1 - 4. payer info
        setValues({
          ...values,
          payMethod: tempPaymethod[0].value,
          name: result.payer.name,
          phone: result.payer.phone,
          email: result.payer.email || '',
          address: result.payer.address || '',
          postalCode: result.payer.postalCode || '',
          paymentCode: result.paymentCode,
        });
        setLoading(false);
      } else if (info.resCode) {
        setLoading(false);
        failAPI(info.resCode);
      }
    }
  };
  /* --- end ticket info setting --- */

  /* --- start user info change --- */
  const onChangeInfo = (value, name) => {
    setValues({ ...values, [name]: value });
  };
  /* --- end user info change --- */

  /* --- start payment process --- */
  useEffect(() => {
    // 1. 티켓정보 불러오기
    if (paymentCode) {
      paymentInfoInquiry();
    }

    // clean up
    return () => {
      setLoading(true);
    };
  }, [paymentCode]);
  /* --- end payment process --- */

  /* --- start warning modal popup background dim --- */
  useEffect(() => {
    // warning modal popup background dim
    if (dim) {
      document.body.style.cssText = ` 
      overflow-y: hidden; `;
    } else {
      document.body.style.cssText = ``;
    }

    return () => {
      document.body.style.cssText = ``;
    };
  }, [dim]);
  /* --- end warning modal popup background dim --- */

  /* --- start select ticket --- */
  const onChangeProduct = (selected) => {
    // api로 보낼 value setting
    setValues({ ...values, productCode: selected.productCode });

    // 프론트에서 노출 시킬 data setting
    setSelectProduct({ ...selected });
  };
  /* --- end select ticket --- */

  /* --- start 약관동의 --- */
  const [agrees, setAgrees] = useState([false, false, false]);

  const onChangeAgrees = (value, name, checked) => {
    if (name === 'all') {
      if (checked) {
        setAgrees([true, true, true]);
      } else {
        setAgrees([false, false, false]);
      }
    } else {
      const tempArray = [...agrees];
      tempArray[Number(name)] = checked;
      setAgrees([...tempArray]);
    }
  };
  /* --- end 약관동의 --- */

  /* --- start onClick payment --- */
  const payment = async () => {
    // 2. 결제 ID(mid) 발급
    setLoading(true);
    const [info] = await paymentApi.issuedId({
      ...values,
    });
    if (info) {
      if (info.result) {
        // mid 발급 완료 후 eximbay 호출
        setLoading(false);
        openEximbay(info.result);
      } else if (info.resCode) {
        setLoading(false);
        failAPI(info.resCode);
      }
    } else {
      setLoading(false);
      failAPI(500);
    }
  };
  /* --- end onClick payment --- */

  /* --- start attach eximbay --- */
  useEffect(() => {
    const jquery = document.createElement('script');
    jquery.src = 'https://code.jquery.com/jquery-1.12.4.min.js';
    const iamport = document.createElement('script');
    iamport.src = 'https://cdn.iamport.kr/js/iamport.payment-1.2.0.js';
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    // clean up
    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    };
  }, []);
  /* --- end attach eximbay --- */

  /* --- start eximbay process --- */
  const openEximbay = async (payInfo) => {
    // 3. eximbay 호출
     setDim(true);
    const { IMP } = window;
    IMP.init(payInfo.impCode);

    const payData = {
      pg: payInfo.pgName,
      pay_method: payInfo.payMethod,
      language: payInfo.currency.toUpperCase() === 'USD' ? 'en' : 'ko',
      currency: payInfo.currency.toUpperCase(),
      merchant_uid: payInfo.merchantUid,
      name: selectProduct.name,
      amount: payInfo.price,
      buyer_email: payInfo.buyer_email,
      buyer_name: payInfo.buyer_name,
      buyer_tel: payInfo.buyer_phone,
      buyer_addr: payInfo.buyer_addr,
      buyer_postcode: payInfo.buyer_postal_code,
      popup: !isMobile,
      // only mobile
      // 4. check page 이동
      m_redirect_url: `${originAllPath}/check`,
      custom_data: values.paymentCode,
    };

    // only pc
    IMP.request_pay(payData, callbackFunc);
  };

  const callbackFunc = (response) => {
    // eslint-disable-next-line camelcase
    const { success, error_msg } = response;
    if (success) {
      confirmPay(response);
    } else {
      failAPI();
    }
  };

  const confirmPay = (response) => {
    // 4. check page 이동
    window.location.replace(
      `${originAllPath}/check?imp_uid=${response.imp_uid}&merchant_uid=${response.merchant_uid}&imp_success=true`,
    );
    setDim(false);
  };
  /* --- end eximbay process --- */

  /* --- start error open --- */
  const failAPI = (errorCode) => {
    setAlert(
      errorCode
        ? TEXT.ERROR[info?.sysLang || 'kor'][errorCode]
        : TEXT.ERROR[info?.sysLang || 'kor'].default,
    );

    setTimeout(() => {
      setAlert();
      setDim(false);
    }, [2000]);
  };
  /* --- end error open --- */

  return loading ? (
    <Loading />
  ) : (
    <>
      {dim && (
        <Div
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          bgColor={hexToRGB('fontBlack', 0.66)}
          zIndex={9999}
        />
      )}
      {alert && (
        <Div
          radius="6px"
          bgColor="colorDE"
          padding="13px 40px"
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
          shadow
          zIndex={9999}
          animation={slideUp('-50%')}
        >
          <LineText textAlign="center" lineText={alert} />
        </Div>
      )}
      {(info && (
        <MainPresenter
          isMobile={isMobile}
          info={info}
          values={values}
          setValues={setValues}
          payMethods={payMethods}
          productList={productList}
          onChangeInfo={onChangeInfo}
          agrees={agrees}
          setAgrees={setAgrees}
          onChangeAgrees={onChangeAgrees}
          onChangeProduct={onChangeProduct}
          payment={payment}
          selectProduct={selectProduct}
        />
      )) || <></>}
    </>
  );
}

export default MainContainer;
