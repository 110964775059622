import { isEmptyObj } from './checkData';

export const apiGet = (filters) => {
  let result = '';
  if (filters && !isEmptyObj(filters)) {
    const cleansing = {};
    const keys = Object.keys(filters);
    keys.forEach((key) => {
      if (filters[key]) {
        cleansing[key] = filters[key];
      }
    });

    const keys2 = Object.keys(cleansing);
    result = (keys2.length > 0 && '?') || '';
    keys2.forEach((key) => {
      result += `${key}=${cleansing[key]}&`;
    });
    result.slice(0, -1);
  }

  return result;
};

export const apiPost = (params) => {
  const cleansing = {};
  if (params && !isEmptyObj(params)) {
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        params[key] !== null &&
        params[key] !== undefined &&
        params[key] !== ''
      ) {
        cleansing[key] = params[key];
      }
    });
  }
  return cleansing;
};
