const TEXT = {
  ERROR: {
    kor: {
      default: '결제요청에 실패하였습니다.',
      500: `서버와의 통신이 불안정합니다. 잠시 뒤 다시 시도해주세요.\n지속적으로 발생할 경우, 문의 바랍니다.`,
      450: `서버와의 통신이 불안정합니다. 잠시 뒤 다시 시도해주세요.\n지속적으로 발생할 경우, 문의 바랍니다.`,
      452: `서버와의 통신이 불안정합니다. 잠시 뒤 다시 시도해주세요.\n지속적으로 발생할 경우, 문의 바랍니다.`,
      453: `서버와의 통신이 불안정합니다. 잠시 뒤 다시 시도해주세요.\n지속적으로 발생할 경우, 문의 바랍니다.`,
      454: `서버와의 통신이 불안정합니다. 잠시 뒤 다시 시도해주세요.\n지속적으로 발생할 경우, 문의 바랍니다.`,
      455: `서버와의 통신이 불안정합니다. 잠시 뒤 다시 시도해주세요.\n지속적으로 발생할 경우, 문의 바랍니다.`,
      456: '이미 결제 완료된 대상입니다.',
      461: '결제 가능한 기간이 아닙니다.',
      462: '결제 기간이 마감되었습니다.',
    },
    eng: {
      default: 'Failed to request payment.',
      500: `Communication with the server is unstable. Please try again later.\n
      If it persists, Please contact us.`,
      450: `Communication with the server is unstable. Please try again later.\n
          If it persists, Please contact us.`,
      452: `Communication with the server is unstable. Please try again later.\n
          If it persists, Please contact us.`,
      453: `Communication with the server is unstable. Please try again later.\n
          If it persists, Please contact us.`,
      454: `Communication with the server is unstable. Please try again later.\n
          If it persists, Please contact us.`,
      455: `Communication with the server is unstable. Please try again later.\n
          If it persists, Please contact us.`,
      456: 'Already paid',
      461: 'Not open yet',
      462: 'Close',
    },
  },
  PAYMENT: {
    kor: {
      payment: '결제하기',
      cancel: '취소',
      amount: '최종 결제금액',
      info_label: '결제자 정보',
      view_fold: '접어보기',
      view_unfold: '펼쳐보기',
      name: '이름',
      phone: '연락처',
      select: '선택',
      payment_method: '결제 수단',
      view: '보기',
      agree_all: '전체 동의',
      terms: '서비스 이용 약관 동의 (필수)',
      terms_third: '제 3자 정보제공 약관 동의 (필수)',
      terms_purchase: '구매조건 확인 및 결제대행 약관 동의 (필수)',
    },
    eng: {
      payment: 'Payment',
      cancel: 'Cancel',
      amount: 'Total payment amount',
      info_label: 'Payer info',
      view_fold: 'fold',
      view_unfold: 'unfold',
      name: 'Name',
      phone: 'Phone',
      select: 'Select',
      payment_method: 'Payment method',
      view: 'view',
      agree_all: 'Agree to all',
      terms: 'Terms and Conditions (Required)',
      terms_third: 'Disclosure of Information to Third Parties (Required)',
      terms_purchase:
        'Purchase Conditions and Payment Agency Confirmation (Required)',
    },
  },
};

export default TEXT;
