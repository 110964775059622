import React from 'react';
import { Div, RowDiv } from 'styles/CommonCSS';
import { ReactComponent as CloseIcon } from 'assets/icon/close.svg';
import Text from 'components/Element/Text';
import History from 'components/Order/History';
import Cancel from 'components/Order/Cancel';
import { ReactComponent as BackButtonIcon } from 'assets/icon/previous.svg';

function OrderPresenter({
  isMobile,
  info,
  page,
  setPage,
  cancelItem,
  returnUrl,
}) {
  return (
    <Div
      width="100%"
      height={(isMobile && 'auto') || '520'}
      maxHeight={(isMobile && 'unset') || '520'}
      minHeight={(isMobile && '100vh') || 'unset'}
      pt={(isMobile && 46) || '0'}
      bgColor="background"
      style={(isMobile && '') || { overflow: 'hidden' }}
    >
      {isMobile && (
        <Div
          width="100%"
          height={46}
          padding="10px 20px"
          bgColor={(page === 'history' && 'white') || 'transparent'}
          position="fixed"
          top="0"
          left="0"
          zIndex={9999}
          onClick={() => {
            if (page === 'history') {
              window.location.replace(returnUrl);
            } else {
              setPage('history');
            }
          }}
        >
          <BackButtonIcon />
        </Div>
      )}
      <Div
        width="100%"
        hegiht={(isMobile && 'auto') || 64}
        padding={(isMobile && '34px 30px 0 30px') || '20px 20px 0 20px'}
        bgColor={(page === 'history' && 'white') || 'transparent'}
      >
        {!isMobile && (
          <RowDiv width="100%" justify="flex-end" mb={4}>
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => window.close()}
            />
          </RowDiv>
        )}
        <Text size={20} weight={700} mSize={18}>
          {(page === 'cancel' && '환불확인') || '결제내역'}
        </Text>
      </Div>
      <Div
        height={isMobile ? '100%' : 'calc(100% - 64px)'}
        style={(isMobile && '') || { overflowY: 'overlay' }}
        scrollbarCss={!isMobile}
      >
        {(page === 'cancel' && (
          <Cancel
            setPage={setPage}
            cancelItem={cancelItem}
            isMobile={isMobile}
          />
        )) || (
          <History
            info={info}
            setPage={setPage}
            returnUrl={returnUrl}
            isMobile={isMobile}
          />
        )}
      </Div>
    </Div>
  );
}

export default OrderPresenter;
