import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as DropIcon } from 'assets/icon/input/drop.svg';
import Text from 'components/Element/Text';
import { Div, flexRow, Relative, scrollbar } from 'styles/CommonCSS';
import { input, inputSize } from 'styles/InputCSS';
import hexToRGB from 'modules/hexToRGB';

function Select({
  isOpen,
  setIsOpen,
  list,
  value,
  name,
  keyName,
  onChange,
  placeholder,
  all,
  disabled,
  ...props
}) {
  const toggling = (event) => {
    event.stopPropagation();
    setIsOpen(isOpen === name ? '' : name);
  };

  const onOptionClicked = (event, selected) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(selected, name);
    setIsOpen(false);
  };

  return (
    <Wrap readOnly={disabled} className="select" {...props}>
      <SelectButton
        onClick={(event) => !disabled && toggling(event)}
        disabled={disabled}
      >
        <Text
          color={value === 'all' || value ? 'fontBlack' : 'fontGray'}
          line={1}
          mSize={12}
        >
          {value ? value.name : placeholder}
        </Text>
        <Arrow open={isOpen === name} />
      </SelectButton>
      <SelectOptionDiv isOpen={isOpen === name}>
        {all && (
          <SelectOption
            onClick={(event) => onOptionClicked(event, 'all')}
            selected={value === 'all'}
          >
            <Text>{all || '전체'}</Text>
          </SelectOption>
        )}
        {list?.map((item, index) => (
          <Div
            key={`select${item}${index}`}
            minHeight={70}
            mMinHeight={56}
            padding="2px"
            borderBottom={
              index !== list.length - 1 && { color: 'colorCC', opacity: '0.56' }
            }
          >
            <SelectOption
              align="center"
              selected={item === value}
              onClick={(event) => onOptionClicked(event, item)}
            >
              {item.name && (
                <Text
                  mb={6}
                  mMb={4}
                  mSize={12}
                  lineHeight="18px"
                  mLineHeight="16px"
                >
                  {item.name}
                </Text>
              )}
              {item.desc && (
                <Text
                  mb={6}
                  mMb={4}
                  mSize={12}
                  lineHeight="18px"
                  mLineHeight="16px"
                >
                  {item.desc}
                </Text>
              )}
              <Text lineHeight="18px" mLineHeight="16px" mSize={12}>
                {`${item.price} ${
                  item.currency.toUpperCase() === 'USD' ? '$' : '원'
                }`}
              </Text>
            </SelectOption>
          </Div>
        ))}
      </SelectOptionDiv>
    </Wrap>
  );
}

const Wrap = styled(Relative)`
  ${input}
  ${inputSize}
  padding: 0;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0;
  }
`;

const SelectButton = styled.button`
  ${flexRow('center', 'space-between')}
  padding: 11px 10px;
  width: 100%;
  height: 100%;
  border-radius: 3px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.main};
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => theme.element.mPadding};
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: ${({ theme }) => theme.element.sPadding};
  }
`;

const SelectOptionDiv = styled.div`
  border-radius: ${({ theme }) => theme.element.input.border};
  z-index: 50;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 3px;
  max-height: ${({ isOpen }) => (isOpen ? '210px' : '0px')};
  overflow-y: overlay;
  overflow-x: hidden;
  border: ${({ isOpen, theme }) =>
    `${isOpen ? '1px' : '0px'} solid ${theme.colors.colorDE}`};
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

  ${scrollbar}

  @media ${({ theme }) => theme.device.tablet} {
    max-height: ${({ isOpen, theme }) => (isOpen ? `168px` : '0px')};
  }
  @media ${({ theme }) => theme.device.mobile} {
    max-height: ${({ isOpen, theme }) => (isOpen ? `168px` : '0px')};
  }
`;

const SelectOption = styled(Div)`
  height: 100%;
  padding: 12px 8px;
  cursor: pointer;
  border-radius: 2px;
  background-color: ${({ selected, theme }) =>
    selected ? hexToRGB(theme.colors.main, 0.8) : 'white'};

  &:hover {
    background-color: ${({ theme }) => hexToRGB(theme.colors.main, 0.3)};
    transition: 0.2s ease-in-out;
  }

  @media ${({ theme }) => theme.device.tablet} {
    min-height: ${({ theme }) => theme.element.input.mHeight};
    padding: 8px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    min-height: ${({ theme }) => theme.element.input.sHeight};
    padding: 8px;
  }
`;

const spin = (to) => keyframes`
  from { transform: rotate(${to}deg); }
  to { transform: rotate(${to + 180}deg); }
`;

const Arrow = styled(DropIcon)`
  -webkit-animation: ${({ open }) => (open ? spin(0) : spin(180))} 0.6s
    ease-in-out both;
  animation: ${({ open }) => (open ? spin(0) : spin(180))} 0.6s ease-in-out both;
  fill: ${({ disabled, theme }) =>
    disabled ? theme.colors.colorDE : theme.colors.main};
  width: 14px;
  height: 10px;
`;

export default Select;
