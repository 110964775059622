import hexToRGB from 'modules/hexToRGB';
import { css, keyframes } from 'styled-components';

const jelloKeyframes = keyframes` 0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }`;

export const jello = css`
  -webkit-animation: ${jelloKeyframes} 0.9s both;
  animation: ${jelloKeyframes} 0.9s both;
`;

const shakeKeyframes = (fill) => keyframes` 0%,
100% {
  
  -webkit-transform: rotate(0deg) scale(1);
          transform: rotate(0deg) scale(1);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; 
}
10% {
  fill:${fill};
  -webkit-transform: rotate(2deg) scale(1.3);
          transform: rotate(2deg) scale(1.3);
}
20%,
40%,
60% {
  fill:${fill};
  -webkit-transform: rotate(-4deg) scale(1.3);
          transform: rotate(-4deg) scale(1.3);
}
30%,
50%,
70% {
  fill:${fill};
  -webkit-transform: rotate(4deg) scale(1.3);
          transform: rotate(4deg) scale(1.3);
}
80% {
  fill:${fill};
  -webkit-transform: rotate(-2deg) scale(1.3);
          transform: rotate(-2deg) scale(1.3);
}
90% {
  fill:${fill};
  -webkit-transform: rotate(2deg)  scale(1.3);
          transform: rotate(2deg)  scale(1.3);
}`;

export const shake = (fill) => css`
  -webkit-animation: ${shakeKeyframes(fill)} 1.3s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: ${shakeKeyframes(fill)} 1.3s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
`;

const shadowKeyframes = (fill, position) => keyframes` 0% { 
  -webkit-box-shadow: 0 0 0 0 ${hexToRGB(fill, 0)}, 0 0 0 0 ${hexToRGB(
  fill,
  0,
)};
  box-shadow: 0 0 0 0 ${hexToRGB(fill, 0)}, 0 0 0 0 ${hexToRGB(fill, 0)};
}
100% { 
  -webkit-box-shadow: ${position?.x ? `${position.x}px` : '6px'} 
  ${position?.y ? `${position.y}px` : '6px'} 
  ${position?.r ? `${position.r}px` : '10px'}  
  ${hexToRGB(fill, position?.opacity ? position.opacity : 0.16)},
  ${position?.x ? `${position.x}px` : '6px'} 
  ${position?.y ? `${position.y}px` : '6px'} 
  ${position?.r ? `${position.r}px` : '10px'}  
  ${hexToRGB(fill, position?.opacity ? position.opacity : 0.16)};

  box-shadow: ${position?.x ? `${position.x}px` : '6px'} 
  ${position?.y ? `${position.y}px` : '6px'} 
  ${position?.r ? `${position.r}px` : '10px'}  
  ${hexToRGB(fill, position?.opacity ? position.opacity : 0.16)},
  ${position?.x ? `${position.x}px` : '6px'} 
  ${position?.y ? `${position.y}px` : '6px'} 
  ${position?.r ? `${position.r}px` : '10px'}  
  ${hexToRGB(fill, position?.opacity ? position.opacity : 0.16)};
} 
 `;

export const shadowAnimation = (fill, position) => css`
  -webkit-animation: ${shadowKeyframes(fill, position)} 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ${shadowKeyframes(fill, position)} 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const scaleUpKeyframes = keyframes`
0%{
  -webkit-transform: scale(1);
  transform: scale(1);
}100%{
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}`;

export const scaleUpAnimation = css`
  -webkit-animation: ${scaleUpKeyframes} 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ${scaleUpKeyframes} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const shakeBellKeyframes = keyframes`0%{
  transform: rotate(0deg);
}
10%{
  transform: rotate(45deg);
}
20%{
  transform: rotate(-45deg);
}
30%{
  transform: rotate(30deg);
}
40%{
  transform: rotate(-30deg);
}
50%{
  transform: rotate(10deg);
}
60%{
  transform: rotate(-10deg);
}
70%{
  transform: rotate(0deg);
}
100%{
  transform: rotate(0deg);
}`;

export const shakeBellAnimation = css`
  transform-origin: 50% 0%;
  -webkit-animation: ${shakeBellKeyframes} 1.2s ease-in-out;
  animation: ${shakeBellKeyframes} 1.2s ease-in-out;
`;

const slideUpKeyframe = (y) => keyframes`
0% {
  -webkit-transform: translate(-50%,100px);
          transform: translate(-50%,100px);
  opacity: 0;
}
100% {
  -webkit-transform: translate(-50%,${y});
          transform: translate(-50%,${y});
  opacity: 1;
}`;

export const slideUp = (y) => css`
  -webkit-animation: ${slideUpKeyframe(y)} 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ${slideUpKeyframe(y)} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
`;
