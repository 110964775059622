import Button from 'components/Element/Button';
import Text from 'components/Element/Text';
import React from 'react';
import { ColDiv } from 'styles/CommonCSS';

function Cancel({ setPage, cancelItem, isMobile }) {
  return (
    <ColDiv
      height={(isMobile && 'auto') || '100%'}
      padding={(isMobile && '10px 30px 20px 30px') || '20px'}
      align="space-between"
    >
      <Text flex={1} minHeight={(isMobile && 431) || 'unset'}>
        환불 조항 노출 영역입니다.
      </Text>
      <Button mb={10} active text="취소" onClick={() => setPage('history')} />
      <Button text="환불하기" type="outline" onClick={cancelItem} />
    </ColDiv>
  );
}

export default Cancel;
