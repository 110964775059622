import React, { useCallback, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/Theme';
import GlobalStyle from 'styles/GlobalStyles';
import ScrollToTop from 'components/Element/ScrollToTop';
import IEComponent from 'components/Element/IE/IEComponent';
import checkIE from 'modules/checkIE';
import Main from 'routes/Main';
import NotFound from 'routes/NotFound';
import Check from 'routes/Check';
import Order from 'routes/Order';

function App(props) {
  const isIE = checkIE();
  const [userTheme, setUserTheme] = useState({ ...theme });

  const themeHandler = useCallback((target) => {
    setUserTheme({ ...theme, colors: { ...theme.colors, main: target } });
  }, []);

  return isIE ? (
    <IEComponent />
  ) : (
    <ThemeProvider theme={userTheme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route
              path="/orderSheet/:paymentCode"
              element={<Main themeHandler={themeHandler} />}
            />
            <Route path="/orderSheet/:paymentCode/check" element={<Check />} />
            <Route path="/order" element={<Order />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
