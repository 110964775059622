import axios from 'axios';
import { apiPost } from 'modules/apiCleansing';

const makeRequest = (type, path, params) => {
  if (type === 'get') {
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...params,
      },
    });
  } else if (type === 'post') {
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...params,
    });
  } else if (type === 'patch') {
    return axios.patch(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...params,
    });
  } else if (type === 'delete') {
    return axios.delete(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...params,
      },
    });
  } else if (type === 'fileUpload') {
    // upload file
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, params);
  } else if (type === 'excelDownload' || type === 'zipDownload') {
    // download file
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      type:
        type === 'excel'
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          : '.zip,.rar,.7zip',
      responseType: 'blob',
    });
  }
};

const getResults = async (type, path, params = {}) => {
  try {
    const {
      data: { results },
      data,
    } = await makeRequest(type, path, params);
    return [results || data, null];
  } catch (e) {
    console.error(e);
    return [null, e.response?.data, e.response?.status];
  }
};

// eslint-disable-next-line import/prefer-default-export
export const paymentApi = {
  inquiry: (params) => getResults('post', `payment/info`, params),
  issuedId: (params) => getResults('post', `payment/mid`, apiPost(params)),
  result: (params) => getResults('post', `payment/confirm`, params),
  order: (params) => getResults('post', `payment/order`, params),
  cancel: (params) => getResults('delete', `payment/cancel`, params),
};
