import Button from 'components/Element/Button';
import ImageComponent from 'components/Element/Image';
import Text from 'components/Element/Text';
import CheckBox from 'components/Input/CheckBox';
import Input from 'components/Input/Input';
import Select from 'components/Input/Select';
import useSelectOpen from 'hooks/useSelectOpen';
import hexToRGB from 'modules/hexToRGB';
import React, { useState } from 'react';
import { ColDiv, Div, GridDiv, RowDiv, Span } from 'styles/CommonCSS';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import LineText from 'components/Element/LineText';
import TEXT from 'data/language';
import AgreeBox from 'components/AgreeBox';

function MainPresenter({
  isMobile,
  payMethods,
  info,
  productList,
  values,
  setValues,
  onChangeInfo,
  agrees,
  onChangeAgrees,
  onChangeProduct,
  payment,
  selectProduct,
}) {
  const [infoOpen, setInfoOpen] = useState(true);
  const { isOpen, setIsOpen } = useSelectOpen();
  return (
    <ColDiv maxWidth={1280} minHeight="100vh" margin="0 auto">
      <Div
        bgColor="white"
        padding="60px 60px 40px 60px"
        width="100%"
        mb={14}
        mPadding="60px 30px 10px 30px"
        mMb="0"
      >
        <Text size={24} mSize={18} weight={700} mb={20} mMb={10}>
          {TEXT.PAYMENT[info.sysLang || 'kor'].payment}
        </Text>
        {!isMobile && info?.headerImg && (
          <ImageComponent src={info?.headerImg} width="100%" radius="6px" />
        )}
        {isMobile && info?.headerImgM && (
          <ImageComponent src={info?.headerImgM} width="100%" radius="6px" />
        )}
      </Div>
      <Div width="100%" flex={1} bgColor="white" mBgColor="transparent">
        <RowDiv width="100%" mDirection>
          <Div
            width="50%"
            mWidth="100%"
            bgColor="white"
            padding="40px 60px 30px 60px"
            mPadding="0 30px 20px 30px"
            mMb={10}
          >
            <Text size={18} mSize={16} weight={700}>
              {info?.name}
            </Text>
            <Div
              width="100%"
              height="1px"
              bgColor={hexToRGB('#CCCCCC', 0.56)}
              mt={20}
              mb={20}
            ></Div>
            <Text size={14} mSize={12} weight={700} mb={4}>
              {info?.info1Name}
            </Text>
            <LineText
              lineText={info?.info1}
              size={14}
              mSize={12}
              lineHeight="20px"
              mLineHeight="16px"
            />
            <Text size={14} mSize={12} weight={700} mb={4} mt={20}>
              {info?.info2Name}
            </Text>
            <LineText
              lineText={info?.info2}
              size={14}
              mSize={12}
              lineHeight="20px"
              mLineHeight="16px"
            />
          </Div>
          <Div width="50%" mWidth="100%">
            <Div
              bgColor="white"
              padding="40px 60px 30px 60px"
              mPadding="20px 30px"
              mMb={10}
            >
              <Select
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                list={productList}
                placeholder={TEXT.PAYMENT[info.sysLang || 'kor'].select}
                onChange={onChangeProduct}
                value={productList?.find(
                  (el) => el.productCode === values.productCode,
                )}
              />
            </Div>
            <Div
              bgColor="white"
              padding="30px 60px"
              mPadding="20px 30px"
              mMb={10}
            >
              <Text size={18} mSize={14} weight={700} mb={20}>
                {TEXT.PAYMENT[info.sysLang || 'kor'].amount}
              </Text>
              <Text size={18} mSize={16} weight={700} mb={20} textAlign="right">
                {selectProduct?.price || 0}
                &nbsp;
                <Span size={14} mSize={12} weight={400}>
                  {(selectProduct?.currency.toUpperCase() === 'USD' && '$') ||
                    (selectProduct?.currency.toUpperCase() === 'KRW' && '원') ||
                    (info.sysLang === 'eng' && '$') ||
                    (info.sysLang === 'kor' && '원')}
                </Span>
              </Text>
            </Div>
          </Div>
        </RowDiv>
        <RowDiv width="100%" mDirection>
          <Div width="50%" mWidth="100%" bgColor="white" mMb={10}>
            <Div
              bgColor="white"
              padding="30px 60px 0px 60px"
              mPadding="20px 30px"
            >
              <RowDiv align="center" justify="space-between">
                <Text size={18} mSize={14} weight={700}>
                  {TEXT.PAYMENT[info.sysLang || 'kor'].info_label}
                </Text>
                {isMobile && (
                  <RowDiv
                    align="center"
                    gap={4}
                    onClick={() => setInfoOpen(!infoOpen)}
                  >
                    <Text size={12} color="color80">
                      {(infoOpen &&
                        TEXT.PAYMENT[info.sysLang || 'kor'].view_fold) ||
                        TEXT.PAYMENT[info.sysLang || 'kor'].view_unfold}
                    </Text>
                    <ArrowIcon
                      style={{
                        transform: `rotate(${infoOpen ? '0deg' : '180deg'})`,
                      }}
                    />
                  </RowDiv>
                )}
              </RowDiv>
              {infoOpen && (
                <Div mt={20}>
                  <Text size={14} mSize={12} mb={4}>
                    {TEXT.PAYMENT[info.sysLang || 'kor'].name}
                    <Span color="warningColor">*</Span>
                  </Text>
                  <Input
                    mb={20}
                    value={values.name}
                    name="name"
                    onChange={onChangeInfo}
                  />
                  <Text size={14} mb={4} mSize={12}>
                    {TEXT.PAYMENT[info.sysLang || 'kor'].phone}
                    <Span color="warningColor">*</Span>
                  </Text>
                  <Input
                    value={values.phone}
                    name="phone"
                    onChange={onChangeInfo}
                  />
                </Div>
              )}
            </Div>
          </Div>
          <Div
            width="50%"
            mWidth="100%"
            bgColor="white"
            padding="30px 60px 40px 60px"
            mPadding="20px 30px 40px 30px"
          >
            <Text size={18} mSize={14} weight={700} mb={20}>
              {TEXT.PAYMENT[info.sysLang || 'kor'].payment_method}
            </Text>
            <GridDiv
              rowGap={10}
              colGap={10}
              mColGap={payMethods.length % 2 === 0 ? 5 : 3}
              mRowGap={payMethods.length % 2 === 0 ? 5 : 3}
              mb={30}
              grid={
                (payMethods.length > 1 &&
                  (payMethods.length % 2 === 0 ? 2 : 3)) ||
                1
              }
            >
              {payMethods?.map((method) => (
                <RowDiv
                  key={method.value}
                  align="center"
                  justify="center"
                  radius="4px"
                  border={{
                    color:
                      values.payMethod === method.value ? 'main' : 'colorCC',
                  }}
                  height={40}
                  mHeight={36}
                  flex={1}
                  pointer
                  onClick={() =>
                    setValues({ ...values, payMethod: method.value })
                  }
                >
                  <Text
                    color={
                      values.payMethod === method.value ? 'main' : 'colorCC'
                    }
                    weight={values.payMethod === method.value ? '700' : '400'}
                    size={14}
                    mSize={12}
                    lineHeight={1}
                    textAlign="center"
                  >
                    {method.label}
                  </Text>
                </RowDiv>
              ))}
            </GridDiv>
            <Div>
              <CheckBox
                onChange={onChangeAgrees}
                checked={agrees[0] && agrees[1] && agrees[2]}
                name="all"
              >
                <Text mSize={12}>
                  {TEXT.PAYMENT[info.sysLang || 'kor'].agree_all}
                </Text>
              </CheckBox>
              <Div
                width="100%"
                height="1px"
                bgColor={hexToRGB('colorCC', 0.56)}
                margin="10px 0"
              />
              <AgreeBox
                onChange={onChangeAgrees}
                checked={agrees[0]}
                name="0"
                label={TEXT.PAYMENT[info.sysLang || 'kor'].terms}
                link="https://drive.google.com/file/d/1XWjyg654el3JRPVUWlbTFsB5OsOq6qeW/view"
                viewText={TEXT.PAYMENT[info.sysLang || 'kor'].view}
                mb={10}
                mMb={6}
              />
              <AgreeBox
                onChange={onChangeAgrees}
                checked={agrees[1]}
                name="1"
                label={TEXT.PAYMENT[info.sysLang || 'kor'].terms_third}
                link="https://drive.google.com/file/d/1gpE_EfPa981C-VoIncXBDCVBBxMJA2IN/view?usp=sharing"
                viewText={TEXT.PAYMENT[info.sysLang || 'kor'].view}
                mb={10}
                mMb={6}
              />
              <AgreeBox
                onChange={onChangeAgrees}
                checked={agrees[2]}
                name="2"
                label={TEXT.PAYMENT[info.sysLang || 'kor'].terms_purchase}
                link="https://drive.google.com/file/d/1durPYiQXOtpC2gri-cDOSDhmdZ30iIcM/view"
                viewText={TEXT.PAYMENT[info.sysLang || 'kor'].view}
                mb={30}
                mMb={20}
              />
              <Button
                text={TEXT.PAYMENT[info.sysLang || 'kor'].payment}
                mb={10}
                active={
                  agrees[0] &&
                  agrees[1] &&
                  agrees[2] &&
                  values.productCode &&
                  values.name &&
                  values.phone
                }
                disabled={
                  !agrees[0] ||
                  !agrees[1] ||
                  !agrees[2] ||
                  !values.productCode ||
                  !values.name ||
                  !values.phone
                }
                onClick={payment}
              />
              <Button
                text={TEXT.PAYMENT[info.sysLang || 'kor'].cancel}
                type="outline"
                onClick={() =>
                  window.location.replace(
                    `${info.returnUrl}${
                      (info.returnUrl.includes('?') && '&') || '?'
                    }isSuccess=false`,
                  )
                }
              />
            </Div>
          </Div>
        </RowDiv>
      </Div>
    </ColDiv>
  );
}

export default MainPresenter;
