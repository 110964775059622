/* eslint-disable camelcase */
import { paymentApi } from 'api';
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import CheckPresenter from './CheckPresenter';

function CheckContainer(props) {
  const { paymentCode } = useParams();
  const [params] = useSearchParams();

  const checkResponse = async () => {
    // 결제 결과 대조 api 호출
    const [info] = await paymentApi.result({
      paymentCode,
      impUid: params.get('imp_uid'),
    });
    if (info) {
      window.location.replace(info.result.returnUrl);
    } else {
      // 결제 결과 대조 api 실패
      window.location.replace(
        `${info.result.returnUrl}${
          (info.result.returnUrl.includes('?') && '&') || '?'
        }isSuccess=false`,
      );
    }
  };

  useEffect(() => {
    if (params.get('imp_success')) {
      checkResponse();
    }
  }, [params.get('imp_success')]);

  return <CheckPresenter />;
}

export default CheckContainer;
